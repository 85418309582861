import React from "react";
import { Heading, Text, ListItem, Image } from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import styled from "styled-components";
import obj_oriented from "../../resources/object_oriented.png";
import b_box from "../../resources/black_box.png";
import db_paradigm from "../../resources/db_paradigm.png";
import appbase_white from "../../resources/application_base_white.jpg"
import appbase_concept_ui from "../../resources/application_base_concept_transp.png"

const Quote = styled.blockquote`
  font-style: italic;
  background-color: lightgray;
  padding: 20px 25px;
  margin: 1rem 0;
  max-width: 700px;
  border-radius: 8px;
`;

const Author = styled.div`
  text-align: right;
  margin-top: 20px;
  &:before {
    content: "—— ";
  }
`;

const Introduction = () => {
  return (
    <WikiArticle>
      <WikiArticleMain>
        <section>
          <Heading as="h2">A bit of history</Heading>
          <Text>
            Back to the old times in IT (this is just about 40 or 50 years ago)
            programmers used to write... programs.
          </Text>
          <Text>
            Each program was a single, solid, monolitic unit performing a
            concrete process among many others. In a scenario dominated by batch
            processing, programs used to read an input and write an output,
            normally stored in files, even on tape or hard disk. Interactive
            programs were later designed in a similar way, where the terminal
            was seen just as another input (the keyboard) or output (the screen)
            device.
          </Text>
          <Text>
            But interactive programs presented also other challenges: end users
            were working simultaneously, performing small units of work in
            comparison to the large transactions of batch processing. File
            locking and consistency assurance needed to be handled repeatedly
            among all the programs accessing the same files.
          </Text>
        </section>

        <section>
          <SectionTitle>SQL to the rescue</SectionTitle>
          <Text>
            At the beginning of the 70s, databases were born, coming to solve
            these issues and standardize data access for all programs. No more
            need to ensure consistency on each program, you just declare it once
            and it will be ensured by the database management system.
            Interestingly, the new solution also needed to elaborate new
            concepts and solve problems that arise only when trying to resolve
            something from a new perspective. By solving data access issues in a
            general way, vague concepts needed to be precisely defined and new
            ones came on place. Transactions and the need for characteristics
            like atomicity and isolation became then clear whilst consistency
            and durability were already well-known since working with random
            files.
          </Text>

          <Heading as="h4">Same old problem</Heading>
          <Text>
            Did it ever happen to you, to let your room or apartment become
            totally chaotic ? One day you decide to organize it better and
            it seems that after arranging
            a couple of things or cleaning here and there, it will be done. But
            then, it turns up that the big problems were hiding other ones and
            you end up struggling with the things like opening a russian mamushka doll.
            Something similar can happen also in other areas, like IT, and when some problems
            are solved new ones arise.
          </Text>
          <Text>
            With SQL, a new layer was introduced between data and programs,
            encapsulating several common features and exposing data as the
            result of a request expressed in a certain language. But then,
            programs evolved into systems.
          </Text>
          <Text>
            When programmers needed to write just programs that were running
            isolated from each other, a single unit of code was perfectly
            understandable for the author and (sometimes) also for other people
            that needed to maintain it. But as complexity increased, so did the
            size of these programs that were then partitioned into modules
            that sometimes could even be reused among other systems and
            then ... it was not possible to modify these modules again, without
            affecting the other ones that were using them.
          </Text>
          <Text>
            Again, you have an object that is being handled by many others
            (before it was the data, now are the modules) and you cannot easily
            maintain it without considering how each other is using it.
          </Text>
        </section>

        <section>
          <SectionTitle>OOP to the rescue</SectionTitle>
          <Text>
            If encapsulating data in a database was a good solution for data
            access, why not to try the same for the structure of the programs?
          </Text>
          <Text>
            In this case, the interface was not a language (programs are already
            written in one) but public methods.
          </Text>
          <Text>
            Object Oriented Programming enforced concepts already known from
            modular programming, like cohesion, connection, etc., but it also
            brought them to a new level by a change in the paradigm.
          </Text>
          <Text>
            Even though the roots of this approach are coming from a different
            source, they also ended up encapsulating data and additionally, an
            object is also defined by its "behavior", i.e. the logic around this
            data.
          </Text>
        </section>

        <section>
          <SectionTitle>What are we trying to solve?</SectionTitle>
          <Quote>
            The formulation of a problem is often more essential than its
            solution, which may be merely a matter of mathematical or
            experimental skill.
            <Author>Albert Einstein</Author>
          </Quote>
          <Text>
            According to this short tale of IT history, there was a first
            paradigm related to programming that we can call a "black box"
            approach. The idea is that a program can be defined by its
            input/output and how the result is achieved should be just
            implementation details.
          </Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"7px"}
            src={b_box}
            alt="Input->Program->Output"
          />
          <br />
          <Text>
            Later, when data was encapsulated into databases a new layer was
            introduced. The output of a request is the corresponding response
            and at DB layer it can also produce modifications in the data being
            stored.
          </Text>
          <Text fontWeight={"bold"}>
            From this point of view, the "core" of a system resides in its data.
          </Text>
          <Text>
            A database model was for many years, the centric representation
            based on which people communicate and programs were built.
          </Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"7px"}
            src={db_paradigm}
            alt="DB Model"
          />
          <br />
          <Text>
            Then, due to maintainability problems remaining in the system box, a
            new approach proposed to proceed further with the partition and
            encapsulation of the problem domain until each single object
            containing data and behavior is strongly cohesive and loosly coupled
            with the rest, facilitating its maintenance and reuse.
          </Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"7px"}
            src={obj_oriented}
            alt="Object Oriented Paradigm"
          />
          <br />
          <Text>
            Further refinements of this approach proposed different
            classifications of the myriad of resulting objects, so that those
            responsible for data access, business logic or user interface can be
            grouped and the work better organized.
          </Text>
          <Text>
            In this OO approach however, focus was moved from data storage to
            object lifecycle. A user request can be modelled as a series of
            interactions (object messages) sent among them and the data
            repository was sometimes even seen by extrem positions as a
            necessary evil. The core of a system is now the object model and its
            persistance is only needed to keep the state between uptimes. The
            name of the most famous java persistence framework (Hibernate)
            cleary represents this idea.
          </Text>

          <Heading as="h4">Same old problem</Heading>
          <Text>
            Many years ago, IT was struggling each time several programs were
            accessing and modifying records in the same random file. Data was
            encapsulated, exposed through a well-defined interface (SQL) and
            problem solved. Like in any problem chain, once a problem is solved,
            the other ones become visible. Next was then code organization, so
            code units were grouped around the data they were handling or into
            helper classes and later classified according to the layer they
            belong to. So far, so good. Except that end users are still unhappy,
            specially in large corporations.
          </Text>
          <Text>
            One of the reasons for this dissatisfaction might be that end users
            do not understand (and are not interested into) the technical
            details of the solution. Being a technical person this might be seen
            as a problem of the end user, but the fact is that they are the
            customers who are paying to get the work done. Imagine now that you
            hire construction specialists to build your new house and after
            years of viewing the never-ending construction, these specialists
            are more and more happy, because technical improvements allow them
            to demolish and rebuild the same parts time after time, better and
            better. They might be right, and each local enhancement is certainly
            that, a local enhancement. But you are interested in getting the
            whole house finished.
          </Text>
          <Text>
            It looks like IT sometimes makes small "jumps" every time it is
            struggling with the same issues time after time. Like if you decide
            to close the door of your chaotic room or just throw everything
            away, a new way of viewing the things normally helps to approach the
            topic from a different angle and then, the solution becomes evident.
          </Text>
        </section>

        <section>
          <SectionTitle>FQL to the rescue</SectionTitle>
          <Text>
            We can talk about databases or about programming approaches like
            procedural, object-oriented, functional, logical. But in none of
            these cases, we will be talking about what the end user really
            wants. The harsh reality is that they do not care about these
            topics. For them, the final result is the only thing that matters
            and being in their position, where IT is nothing else than a mean to
            achieve a goal, they are totally right.
          </Text>
          <Text>
            An important point when you try to solve a problem is to define its
            domain. In the case of Databases, it was data. When talking about
            OOP, it was code and how to organize it. In FQL the domain is (not
            surprisingly) Forms.
          </Text>

          <Heading as="h4">What are FORMS?</Heading>
          <Text>A FORM is just a USER TRANSACTION.</Text>
          <Text>
            It is called FORM and not User Transaction, because the intention is
            to bring everything closer to the end user. For an end user, it is
            much easier to understand that there is a Form "Customers" rather
            than a User Transaction "Customers". A form is something a user can
            immediately visualize (and this is the intention). Without coming to
            the detailed characteristics of FQL, we will see here how a
            different domain affects the way of thinking and solving problems.
          </Text>

          <Heading as="h4">How will the system work?</Heading>
          <Text>The system will work in the same way with the exception of the user interface. This means that the FQL code will always be the same and the Application Base will be responsible for processing the FQL commands and converting them into SQL, giving the user the full power of both languages.</Text>
          <Text>The UI can be a Command Line, GUI, custom development, or (in the future) Virtual or Augmented Reality, or voice commands in a conversational mode.</Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"7px"}
            src={appbase_concept_ui}
            alt="Application Base Concept"
          />
          <div id="how-will-the-system-work"></div>
        </section>
      </WikiArticleMain>
      <WikiArticleSubPanel>
        <SectionIndex>
          <ListItem>
            <LinkToSection>SQL to the rescue</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>OOP to the rescue</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>What are we trying to solve?</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>FQL to the rescue</LinkToSection>
          </ListItem>
        </SectionIndex>
      </WikiArticleSubPanel>
    </WikiArticle>
  );
};

export default Introduction;
