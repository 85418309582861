import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';

const ModalGet = ({ setShowModal }) => {
  const { settings, setSettings, fqlToken } = useContext(GlobalContext);

  const handleFillFields = (row) => {
    fqlToken.current = {
      ...fqlToken.current,
      TOKEN: [fqlToken.current.TOKEN[row]],
    };

    const inputs = document.getElementsByClassName("input-ps-cn");
    const refDict = {};

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (input.type === "file") {
        input.textvalue = settings.modals.data[row][input.title.toLowerCase()];
        const index = input.id.split("-")[1];
        const fillElement = document.getElementById(`input-fill-${index}`);
        fillElement.click();
        continue;
      } else if (
        input.parentElement.attributes.reftype &&
        input.parentElement.attributes.reftype.value === "ref-mult"
      ) {
        if (!refDict[input.title]) {
          refDict[input.title] = "done";
          const index = input.id.split("-")[1];
          const fillElement = document.getElementById(`input-fill-${index}`);
          fillElement.attributes.data.value = JSON.stringify(
            settings.modals.data[row]
          );
          fillElement.click();
        }
      } else input.value = settings.modals.data[row][input.title.toLowerCase()];
    }

    setShowModal(false);
    setTimeout(() => {
      setSettings({
        ...settings,
        modals: { ...settings.modals, show: false },
      });
    }, 100);
  };

  const columns = Object.keys(settings.modals.data[0]).map((item) => {
    return {
      accessorKey: item.replaceAll(".", "--"),
      header: item.toUpperCase(),
      size: 150,
    };
  });

  const data = settings.modals.data.map((item) => {
    const row = {};
    Object.keys(item).map((elem) => {
      try {
        const json = JSON.parse(item[elem].toString());
        if (json && json["name"])
          row[elem.replaceAll(".", "--")] = json["name"];
        else row[elem.replaceAll(".", "--")] = item[elem].toString();
      } catch (e) {
        row[elem.replaceAll(".", "--")] = item[elem].toString();
      }
    });
    return row;
  });

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            {Object.keys(settings.modals.data[0]).map(item => <Th>{item.toUpperCase()}</Th>)}
          </Tr>
        </Thead>
        <Tbody>
          {settings.modals.data.map((item, i) => {
            return (
              <Tr onClick={() => handleFillFields(i)}>
                {Object.keys(item).map(elem => {
                  return (
                    <Td>{item[elem].toString()}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ModalGet;
