import React from "react";
import { Link } from "react-router-dom";
import { Heading, Text, ListItem, Image } from "@chakra-ui/react";
import {
  SectionIndex,
  SectionTitle,
  LinkToSection,
} from "../../new_component/organisms/SectionIndex";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import db_ref from "../../resources/db_reference.png";
import CodeExample from "../../new_component/organisms/CodeExample";
import ai_code_generation_transp from "../../resources/AI_code_generation_transp.png";
import fql_locking from "../../resources/fql_locking_business_transparent.png"
import ab_deployment_needed from "../../resources/application_base_concept_deployment_needed.png"
import ab_deployment_no_need from "../../resources/application_base_concept_deployment_no_need.png"
import low_code from "../../resources/lowCode.gif"

const ArtificialIntelligence = () => {
  return (
    <WikiArticle>
      <WikiArticleMain>
        <section>
          <Heading as="h2">AI & No/Low Code</Heading>
          <SectionTitle>What About AI?</SectionTitle>
          <Text>
            With the latest achievements in Artificial Intelligence and LLMs,
            we may wonder if such a different approach as FQL and an Application Base
            still makes sense.
          </Text>
          <Text>
            The short answer is YES!
          </Text>
          <Text>
            While AI is capable of generating code,
            it never builds everything from scratch. For example, when needed, it always uses databases.
            Of course, with the capabilities it has, it wouldn't be too problematic to generate also
            all the DB Logic to ensure consistency and ACID transactions, but ...
            why to make the whole result more complicated, and harder to read, understand and maintain?
          </Text>
          <Text>
            In the same way that AI uses DataBases when generating code, we believe that the whole
            work will be simplified, more consistent, elegant, and maintainable, when using an Application Base.
            It would also be a confirmation that the result is unambiguous and consistent.
          </Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"0px"}
            src={ai_code_generation_transp}
            alt="AI Code Generation"
          />
          <Text>
            IMPORTANT: Please note that the generated code and DB still needs to be deployed.
          </Text>
        </section>
        <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"0px"}
            src={ab_deployment_needed}
            alt="AI Code Generation"
        />

        <section>
          <SectionTitle>What About No/Low Code?</SectionTitle>
          <Text>
            No/Low Code solutions have also gained some momentum.
            Although it can be combined with AI, it is still a different
            approach than direct AI Generated Code,
            It aims to solve a similar problem than an Application Base
            (although in a different way).
          </Text>
          <Text>
            As we are going to see, No/Low Code solutions in general start from:
          </Text>
          <Text>
             {"\u0009"} {'\u25CF'} A DataBase Model
          </Text>
          <Text>
             {"\u0009"} {'\u25CF'} A BPM Notation Diagram
          </Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"0px"}
            src={low_code}
            alt="No/Low Code Solutions"
          />
          <Text>
            This is a different starting point than an Application Base. A different paradigm.
            The modelling and technical knowledge required are very different.
            So it is also the transparency of the system's behavior.
            Maybe not everyone can write FQL, but everyone should
            be able to read it.
          </Text>
          <Text>
            And if End Users can read what the system is doing, there's no need for a "black-box"
            testing approach. It changes everything. Instead of validating some cases and if they are OK,
            assuming that everything else is also OK, you can see what the system does.
            No hidden bugs. Transparency. Clear and explainable behavior.
          </Text>
        </section>

        <section>
          <SectionTitle>Using an Application Base</SectionTitle>
          <Text>
            Using an Application Base changes the whole thing. Although it can be formally
            considered a No/Low Code solution, the reality is a bit different. We build
            systems in a different way, and at a different abstraction level that is human readable.
          </Text>
          <Text>
            Yes, we can combine it with AI in particular (no need of DB models or BPMN diagrams)
            and the results are that not only we are going to build applications in a different way,
            but also the kind of applications we will build are going to be more "conversational".
          </Text>
          <Text>
            The old "Point & Click" paradigm is going to be deprecated and our interactions with
            IT Systems are going to become conversations. If you watched the movie "Her", this is
            the future ahead of us. And an Application Base makes it easier, and closer.
          </Text>
          <Image
            alignSelf={"center"}
            margin={"auto"}
            borderRadius={"0px"}
            src={ab_deployment_no_need}
            alt="AI Code Generation"
          />
        </section>

        <section>
          <SectionTitle></SectionTitle>
          <Text></Text>
        </section>
      </WikiArticleMain>

      <WikiArticleSubPanel>
        <SectionIndex>
          <ListItem>
            <LinkToSection>What About AI?</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>What About Los/No Code?</LinkToSection>
          </ListItem>
          <ListItem>
            <LinkToSection>Using an Application Base</LinkToSection>
          </ListItem>
        </SectionIndex>
      </WikiArticleSubPanel>
    </WikiArticle>
  );
};

export default ArtificialIntelligence;
