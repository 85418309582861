import React from "react";
import {
  WikiArticle,
  WikiArticleMain,
  WikiArticleSubPanel,
} from "../../layouts/WikiArticle";
import { Heading, ListItem, OrderedList, Text, UnorderedList } from "@chakra-ui/react";
import { LinkToSection, SectionIndex } from "../../new_component/organisms/SectionIndex";
import CodeExample from "../../new_component/organisms/CodeExample";

const Concepts = () => (
  <WikiArticle>
    <WikiArticleMain>
    <section>
        <Heading as="h2">Define Concept</Heading>
        <Text>
        The <b>define concept</b> command allows users to create an alias for an existing entity (either a form or another concept), 
        filtered by a specified condition. These aliases, called concepts, dynamically evaluate the defined 
        condition whenever accessed. Since concepts themselves are entities, new concepts can also be defined on 
        top of existing concepts, enabling composable and modular query design.
        </Text>

        <Heading as="h3">Syntax</Heading>
        <CodeExample margin={0} title="Run SQL" run={false} multiline>
            {`define concept <concept_name> is <entity_name> with <condition>`}
          </CodeExample>
        <UnorderedList>
        <ListItem><Text>&lt;concept_name&gt;: The name of the concept that will represent the filtered entity.</Text></ListItem>
        <ListItem><Text>&lt;entity_name&gt;: The name of an existing form or concept</Text></ListItem>
        <ListItem><Text>&lt;condition&gt;: The condition to filter the data.</Text></ListItem>
        </UnorderedList>

        <Heading as="h3">Description</Heading>
        <Text>The <b>define concept</b> command simplifies complex queries by allowing developers to define reusable, 
          named views with built-in conditions. Since concepts are treated as entities in FQL, they can serve as 
          the foundation for other concepts, enabling powerful and flexible abstractions.</Text>

        <Heading as="h3">Example Use Case: Pokemons and Types</Heading>
        <Heading as="h4">Scenario</Heading>

        <Text>We have two forms, Types and Pokemons, that represent Pokemons data and their types. 
          We will define the following concepts:</Text>
        <UnorderedList>
        <ListItem><Text>Water_Pokemons: Pokemons of the "Water" type.</Text></ListItem>
        <ListItem><Text>Young_Water_Pokemons: Water Pokemons that are less than 5 years old.</Text></ListItem>
        </UnorderedList>

        <Heading as="h4">Step 1: Create Forms and Define Concepts</Heading>
        <CodeExample margin={0} title="Run SQL" run={true} multiline>
            {`create form Types(name text not null unique)
create form Pokemons(name text not null unique, age number, type references 1..2 Types.name)
define concept Water_Pokemons is Pokemons with type.name = 'Water'
define concept Young_Water_Pokemons is Water_Pokemons with age < 5`}
          </CodeExample>

        <UnorderedList>
          <ListItem><Text><b>Explanation:</b></Text></ListItem>
          <UnorderedList>
            <ListItem><Text>Types and Pokemons are forms (entities) that store Pokemons types and Pokemons data, respectively.</Text></ListItem>
            <ListItem><Text>Water_Pokemons is a concept (entity) that filters Pokemons to include only those with a type of "Water."</Text></ListItem>
            <ListItem><Text>Young_Water_Pokemons is a concept (entity) that builds on Water_Pokemons, further filtering Pokemons that are less than 5 years old.</Text></ListItem>
          </UnorderedList>
        </UnorderedList>

        <Heading as="h4">Step 2: Populate Entities with Data</Heading>
        <CodeExample margin={0} title="Run SQL" run={true} multiline>
            {`create Types('Fire')
create Types('Water')
create Types('Grass')
create Types('Poison')
create Types('Electric')
create Types('Psychic')

create Pokemons('Charmander', 3, 'Fire')
create Pokemons('Squirtle', 2, 'Water')
create Pokemons('Bulbasaur', 4, ('Grass', 'Poison'))
create Pokemons('Pikachu', 6, 'Electric')
create Pokemons('Slowpoke', 7, ('Water', 'Psychic'))
`}
          </CodeExample>

        <UnorderedList>
          <ListItem><Text><b>Explanation:</b> Adds data to the Types and Pokemons forms.</Text></ListItem>
        </UnorderedList>


        <Heading as="h4">Step 3: Access Data Using the Entities</Heading>
        <OrderedList>
          <ListItem><Text><b>Retrieve All Pokemons:</b></Text></ListItem>
          <CodeExample margin={0} title="Run SQL" run={true} multiline>
            {`get Pokemons(name, type.name, age)`}
          </CodeExample>
          <UnorderedList>
          <ListItem><Text><b>Result:</b> Returns all Pokemons, regardless of type or age.</Text></ListItem>
        </UnorderedList>


        <ListItem><Text><b>Retrieve Water Pokemons:</b></Text></ListItem>
          <CodeExample margin={0} title="Run SQL" run={true} multiline>
            {`get Water_Pokemons(name, type.name, age)`}
          </CodeExample>
          <UnorderedList>
            <ListItem><Text><b>Result:</b> Returns all Pokemons of type "Water," including:</Text></ListItem>
            <UnorderedList>
              <ListItem><Text>Squirtle</Text></ListItem>
              <ListItem><Text>Slowpoke</Text></ListItem>
            </UnorderedList>
          </UnorderedList>

          <ListItem><Text><b>Retrieve a Specific Water Pokemon:</b></Text></ListItem>
          <CodeExample margin={0} title="Run SQL" run={true} multiline>
            {`get Water_Pokemons(name, type.name, age) with name = 'Squirtle'`}
          </CodeExample>
          <UnorderedList>
            <ListItem><Text><b>Result:</b> Returns the record for Squirtle.</Text></ListItem>
          </UnorderedList>

          <ListItem><Text><b>Retrieve Young Water Pokemons:</b></Text></ListItem>
          <CodeExample margin={0} title="Run SQL" run={true} multiline>
            {`get Young_Water_Pokemons(name, type.name, age)`}
          </CodeExample>
          <UnorderedList>
            <ListItem><Text><b>Result:</b> Returns Water Pokemons less than 5 years old:</Text></ListItem>
            <UnorderedList>
              <ListItem><Text>Squirtle</Text></ListItem>
            </UnorderedList>
          </UnorderedList>
        </OrderedList>

        <Heading as="h3">Notes</Heading>
        <UnorderedList>
          <ListItem><Text><b>Entities:</b> Both forms and concepts are entities in FQL, which means any concept can reference an existing form or another concept.</Text></ListItem>
          <ListItem><Text><b>Dynamic Evaluation:</b> Concepts are evaluated dynamically during queries, ensuring that changes in underlying entities are always reflected.</Text></ListItem>
          <ListItem><Text><b>Composability:</b> Building concepts on top of other concepts enables hierarchical and modular query definitions.</Text></ListItem>
        </UnorderedList>

        <Heading as="h3">Benefits</Heading>
        <UnorderedList>
          <ListItem><Text><b>Reusability:</b> Encapsulates complex filtering logic into named, reusable entities.</Text></ListItem>
          <ListItem><Text><b>Flexibility:</b> Supports layering concepts for advanced query designs.</Text></ListItem>
          <ListItem><Text><b>Readability:</b> Improves query readability by replacing complex conditions with meaningful entity names.</Text></ListItem>
        </UnorderedList>

    </section>
    </WikiArticleMain>

    

    <WikiArticleSubPanel>
      <SectionIndex>
        <ListItem>
          <LinkToSection>Introduction</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Organization Commands</LinkToSection>
        </ListItem>
        <ListItem>
          <LinkToSection>Grammar</LinkToSection>
        </ListItem>
      </SectionIndex>
    </WikiArticleSubPanel>
    
  </WikiArticle>
)

export default Concepts