import { useState, useRef, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';
import getCaretCoordinates from "textarea-caret";
import Terminal from "react-console-emulator";
import {
  Box,
  Button,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';
import { GlobalContext } from "../../component/GlobalContext";
import { apiBaseURL } from "../../config";
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

const getSuggestions = async (code, userToken) => {
  return fetch(`${apiBaseURL}/autocomplete`, {
    method: "POST",
    body: new URLSearchParams({
      code: code,
      token: userToken,
    }),
  })
    .then(res => res.json())
    .then(data => {
      return data.suggestions.map(value => typeof value === 'string' ? { name: value } : value);
    });
};

const getFormDataLabels = async (formName, userToken) => {
  return fetch(`${apiBaseURL}/get-form-data-labels`, {
    method: 'POST',
    body: new URLSearchParams({
      form_name: formName,
      token: userToken,
    }),
  })
    .then(res => res.json())
    .then(data => {
      return data.data_labels;
    });
};

class CompletionItem {
  constructor({ label, text, type, references }) {
    this.label = label || text;
    this.text = text;
    this.type = type;
    this.references = references;
  }
  complete(inputElem) {
    const inputText = inputElem.value;
    const leftPart = inputText.slice(0, inputElem.selectionStart).replace(/[a-zA-Z_][a-zA-Z0-9_]*$/, '');
    const cursorPos = leftPart.length + this.text.length
    inputElem.value = leftPart + this.text + inputText.slice(inputElem.selectionEnd);
    inputElem.selectionStart = cursorPos;
    inputElem.selectionEnd = cursorPos;
  }
}

class SnippetItem extends CompletionItem {
  constructor(props) {
    super(props);
    this.type ||= 'snippet';
    this.pos = 0;
  }
  complete(inputElem) {
    super.complete(inputElem);
    const matchedPos = inputElem.value.search(/<<.+?>>/);
    if (matchedPos !== -1) {
      inputElem.selectionStart = matchedPos;
      inputElem.selectionEnd = inputElem.value.slice(matchedPos).indexOf('>>') + matchedPos + 2;
    }
  }
}

const snippetItemDefinitions = [
  {
    label: 'Create Form Snippet',
    text: 'create form <<form name>> (<<params>>)',
  },
  {
    label: 'Define Rule Snippet',
    text: 'define rule <<rule name>>: on <<form name>> when <<conditions>> is invalid',
  },
  {
    label: 'Remove Forms Snippet',
    text: 'remove forms <<form names>>',
  },
  {
    label: 'Show Forms Snippet',
    text: 'show forms <<form names>>',
  },
  {
    label: 'Get Case Snippet',
    text: 'get <<form name>> (<<params>>) with <<conditions>>',
  },
  {
    label: 'Modify Case Snippet',
    text: 'modify <<form name>> (<<params>>) with <<conditions>>',
  },
];

const DrillDownMenu = forwardRef(({ items, userToken, onClickItem }, ref) => {
  const [isDrillDownInProgress, setIsDrillDownInProgress] = useState(false);
  const [drillDownItems, setDrillDownItems] = useState([]);
  const [focusIndex, setFocusIndex] = useState(0);
  const onDrillDown = async (formName) => {
    if (!isDrillDownInProgress) {
      try {
        setIsDrillDownInProgress(true);
        const labels = await getFormDataLabels(formName, userToken)
        setDrillDownItems(labels);
      } finally {
        setIsDrillDownInProgress(false);
      }
    }
  };
  const moveFocus = (amount) => {
    let newIdx = (focusIndex + amount) % items.length;
    if (newIdx < 0) {
      newIdx += items.length;
    }
    setFocusIndex(newIdx);
  };
  const getFocusedItem = () => items[focusIndex];
  useImperativeHandle(ref, () => ({
    moveFocus, getFocusedItem
  }));
  useEffect(() => {
    setFocusIndex(0);
  }, [items]);
  if (!items || items.length === 0) {
    return <div></div>;
  }
  return (
    <Popover trigger="hover" placement="right-start" gutter={0}>
      <Stack spacing={0} py={1} bg="bg" borderRadius={2}>
        {items.map((item, i) => {
          const extraProps = i === focusIndex ? { 'data-focus': true } : {};
          return (
            <Button
              value={item.text} variant="menu" pos="relative"
              onClick={() => onClickItem(item)}
              onMouseEnter={() => setFocusIndex(i)}
              key={`drill-down-${item}-${i}-${items.length}`}
              {...extraProps}
            >
              {item.label}
              <PopoverTrigger>
                <Box pos="absolute" right={0} display={item.type === 'reference' ? '' : 'none'}
                  onMouseEnter={() => onDrillDown(item.references)}
                  px={4} py={2} mr={-2}
                >
                  <FA icon="fa-caret-right" />
                </Box>
              </PopoverTrigger>
            </Button>
          );
        })}
      </Stack>
      <PopoverContent size="md">
        <DrillDownMenu items={(drillDownItems || []).map(item => new CompletionItem({ text: `${items[focusIndex].text}.${item.name}`, label: item.name, type: item.references ? 'reference' : 'data', references: item.references }))} userToken={userToken} onClickItem={onClickItem} />
      </PopoverContent>
    </Popover>
  );
});

const Console = ({ userToken, ...props }) => {
  const { settings, setSettings } = useContext(GlobalContext);
  const terminal = useRef();
  const [completionStatus, setCompletionStatus] = useState('closed');
  const [completionMenuTop, setCompletionMenuTop] = useState(0);
  const [completionMenuLeft, setCompletionMenuLeft] = useState(0);
  const [completionItems, setCompletionItems] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isSnippetMode, setIsSnippetMode] = useState(false);
  const menuRef = useRef();

  const updateCompletionPosition = () => {
    const input = terminal.current.terminalInput.current;
    const pos = getCaretCoordinates(input, input.selectionStart);
    setCompletionMenuLeft(input.getBoundingClientRect().x + pos.left);
    setCompletionMenuTop(input.getBoundingClientRect().y + input.clientHeight + 4);
  };
  const updateSuggestions = (options) => {
    const input = terminal.current.terminalInput.current;
    const text = input.value.slice(0, input.selectionStart);
    let lastToken = text.match(/[a-zA-Z_][a-zA-Z0-9_]*$/);
    lastToken = lastToken && lastToken[0];
    const filteredOptions = lastToken
      ? options.filter(item => item.name.startsWith(lastToken))
      : options;
    const isFirstToken = lastToken ? text.trim(/\s+/).split(/\s+/).length <= 1 : text.search(/\S/) === -1;
    const snippetOptions = isFirstToken
      ? snippetItemDefinitions.filter(snippet => !lastToken || snippet.label.toLowerCase().startsWith(lastToken.toLowerCase())).map(snippet => (new SnippetItem(snippet)))
      : [];
    setSuggestions(
      filteredOptions
        .map(option => new CompletionItem({ text: option.name, type: option.references ? 'reference' : 'data', references: option.references }))
        .concat(snippetOptions)
    );
  };

  const openCompletion = async (text) => {
    setCompletionStatus('opening');
    const input = terminal.current.terminalInput.current;
    const code = typeof text === 'undefined' ? input.value.slice(0, input.selectionStart) : text;
    const options = await getSuggestions(code, userToken);
    if (options) {
      setCompletionItems(options);
      updateSuggestions(options);
      setCompletionStatus('open');
    }
  };
  const closeCompletion = () => {
    setCompletionStatus('closed');
    setCompletionItems([]);
  };

  const handleInput = async (e) => {
    const input = e.target;
    const textBeforeCursor = input.value.slice(0, input.selectionStart);
    switch(e.code) {
      case 'Period':
      case 'Space':
        openCompletion(textBeforeCursor + e.key);
        break;
      case 'Tab':
        e.preventDefault();
        updateCompletionPosition();
        if (isSnippetMode) {
          const matchedPos = input.value.slice(input.selectionStart).search(/<<.+?>>/);
          if (matchedPos !== -1) {
            input.selectionStart += matchedPos;
            input.selectionEnd = input.value.slice(input.selectionStart).indexOf('>>') + input.selectionStart + 2;
            closeCompletion();
            break;
          }
        }
        setIsSnippetMode(false);
        openCompletion(textBeforeCursor.replace(/[a-zA-Z_][a-zA-Z0-9_]*$/, ''));
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        if (completionStatus === 'open') {
          menuRef.current?.moveFocus(e.code === 'ArrowDown' ? 1 : -1);
          e.preventDefault();
          e.stopPropagation();
        }
        break;
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Enter':
        if (completionStatus === 'open') {
          e.preventDefault();
          e.stopPropagation();
          const item = menuRef.current?.getFocusedItem();
          if (item) {
            item.complete(input);
            if (item.type === 'snippet') {
              setIsSnippetMode(true);
            }
          }
          closeCompletion();
        }
        else if (e.code === 'Enter') {
          setIsSnippetMode(false);
        }
        break;
      case 'Escape':
        closeCompletion();
        break;
      case 'Backspace':
        if (e.target.selectionStart !== e.target.selectionEnd) {
          closeCompletion();
        }
        else if (e.target.value.length > 0) {
          const lastChar = e.target.value[e.target.value.length-1];
          if (!lastChar.match(/[a-zA-Z0-9_]/)) {
            closeCompletion();
          }
        }
        else {
          closeCompletion();
        }
        break;
      default:
        const isMetaKey = e.ctrlKey || e.shiftKey || e.metaKey || e.altKey;
        const isTokenChar = !isMetaKey && e.key.match(/^[a-zA-Z0-9_]$/);
        if (completionStatus === 'open' && !isTokenChar) {
          closeCompletion();
        }
        else if (completionStatus === 'closed' && isTokenChar) {
          openCompletion(textBeforeCursor);
        }
        break;
    }
  };
  const handleChange = () => {
    updateCompletionPosition();
    updateSuggestions(completionItems);
  };
  const onClickCompletion = (item) => {
    item.complete(terminal.current.terminalInput.current);
    if (item.type === 'snippet') {
      setIsSnippetMode(true);
    }
    closeCompletion();
    terminal.current.focusTerminal();
  };

  useEffect(() => {
    switch (settings.console.dispatch) {
      case 'define-rule':
        const snippet = new SnippetItem(snippetItemDefinitions.find(i => i.label === 'Define Rule Snippet'));
        snippet.complete(terminal.current.terminalInput.current);
        setIsSnippetMode(true);
        setSettings({
          ...settings,
          console: { ...settings.console, dispatch: '' },
        });
        break;
      default:
        break;
    }
  }, [settings]);

  useEffect(() => {
    const input = terminal.current.terminalInput.current;
    input.addEventListener('keydown', handleInput);
    input.addEventListener('input', handleChange);
    //input.addEventListener('blur', closeCompletion);
    return () => {
      input.removeEventListener('keydown', handleInput);
      input.removeEventListener('input', handleChange);
      //input.removeEventListener('blur', closeCompletion);
    };
  }, [handleInput, openCompletion]);

  return (
    <Box w="100%">
      <Terminal
        ref={terminal}
        {...props}
        promptLabel={<b>fql:~$</b>}
        disabledOnProcess={true}
        hidePromptWhenDisabled={true}
        autoFocus={true}
        errorText={
          "Command '[command]' not found! \nFor runnign FQL commands type '<fql_commands>'\n  Type 'help' or '?' for help."
        }
      />
      <Box pos="fixed" top={`${completionMenuTop}px`} left={`${completionMenuLeft}px`} fontFamily="monospace" visibility={completionStatus === 'open' ? 'visible' : 'hidden'}>
        <Stack spacing={0} py={1} bg="bg" borderRadius={2}>
          <DrillDownMenu ref={menuRef} items={suggestions} userToken={userToken} onClickItem={onClickCompletion} />
        </Stack>
      </Box>
    </Box>
  );
};

export default Console;
