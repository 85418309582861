import { useContext } from "react";
import { GlobalContext } from "../../GlobalContext";

const ModalDelete = ({ onClose }) => {
  const { settings, setSettings, runCode, userInfo, forms, setLoading } =
    useContext(GlobalContext);

  const handleDeleteForm = async () => {
    setSettings({
      ...settings,
      message: { ...settings.message, show: false },
    });
    setLoading(true);
    await runCode({
      code: `remove forms ${settings.modals.data}`,
      token: userInfo.token,
    })
      .then((data) => {
        if (data && data.msg) {
          setSettings({
            ...settings,
            modals: { ...settings.modals, show: false },
          });

          if (data.msg.includes("OK")) {
            forms.current.map((item, index) => {
              if (
                item["FORMS"].toLowerCase() ===
                settings.modals.data.toLowerCase()
              ) {
                forms.current.splice(index, 1);
                return 0;
              }
            });
          }

          setTimeout(() => {
            setLoading(false);
            setSettings({
              ...settings,
              modals: { ...settings.modals, show: false },
              message: {
                ...settings.message,
                status: data.msg.includes("ERROR") ? "error" : "ok",
                show: true,
                data: data.msg,
              },
            });
          }, 500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <p>
        Deleting a form "<b>{settings.modals.data}</b>".
      </p>
      <button
        type="button"
        className="btn btn-secondary"
        id="modal-delete"
        data-bs-dismiss="modal"
        onClick={onClose}
      >
        CANCEL
      </button>

      <button
        type="button"
        className="btn btn-primary-2"
        data-bs-dismiss="modal"
        onClick={handleDeleteForm}
      >
        ACCEPT
      </button>
    </div>
  );
};

export default ModalDelete;
